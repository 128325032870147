export enum Brand {
    AUDI = 9,
    BMW = 4,
    BYD = 32,
    CITROEN = 28,
    CUPRA = 30,
    DS = 17,
    FIAT = 29,
    FORD = 27,
    HONDA = 14,
    HYUNDAI = 6,
    JAGUAR = 11,
    JEEP = 33,
    KIA = 3,
    MAZDA = 25,
    MERCEDES = 10,
    MG = 22,
    MINI = 16,
    NISSAN = 2,
    SEAT = 20,
    SKODA = 21,
    OPEL = 15,
    PEUGEOT = 13,
    POLESTAR = 23,
    RENAULT = 1,
    SMART = 8,
    TESLA = 12,
    VOLVO = 26,
    VW = 5,
    DACIA = 31,
}
